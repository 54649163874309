<template>

  <v-simple-table dense>    
    <template v-slot:default >
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th class="trainings__icon">
          </th>
          <th class="text-left font-weight-regular">
            Группа тренингов
          </th>
          <th class="text-left font-weight-regular">
            Тренинг
          </th>
          <th class="text-left font-weight-regular">
            Уровень План
          </th>
          <th class="text-left font-weight-regular">
            Стартовый Уровень
          </th>
          <th class="text-left font-weight-regular">
            Финальный Уровень
          </th>
          <th>
            Точность
          </th>
          <th class="text-left font-weight-regular">
            Все ответы верные
          </th>
          <th class="text-left font-weight-regular">
            Последовательность верная
          </th>
          <th class="text-left font-weight-regular">
            Очки
          </th>
          <th class="text-left font-weight-regular">
            Время
          </th>
        </tr>
      </thead>
      <tbody>
        <template  v-for="item in tasks">
          <tr :key="item.id" > 
            <td class="w-fit px-1">
              <v-btn v-if="taskRoundLength(item.id)" x-small tile depressed class="trainings__result-count blue-grey lighten-4 px-1  " @click="opened == item.id ? opened = null : opened= item.id">{{ taskRoundLength(item.id) }}</v-btn>
            </td>
            <td>{{ item.training.group }} </td>
            <td>{{ item.training.name }}</td>
            <td>{{ item.levelPlan }}</td>
            <td>{{ taskStartLevel(item.id) }}</td>
            <td>{{ taskEndLevel(item.id) }}</td>
            <td>{{ taskAccuracyTotal(item.id) }}</td>
            <td>{{ taskAllRightTotal(item.id) }}</td>
            <td>{{ taskSequenceTotal(item.id) }}</td>
            <td>{{ taskScore(item.id) }}</td>
            <td>{{ taskTime(item.id) }}</td>
          </tr>
          <tr :key="item.id+'open'">
            <td colspan="11" v-if="opened == item.id" class="pl-7 pr-0 pb-5 white">
              <v-simple-table dense class="table-bordered">
                <template #default >
                   <thead class="grey lighten-3">
                      <tr class="text-caption grey--text ">
                        <th class="text-left font-weight-regular">
                          Номер уровня
                        </th>
                        <th class="text-left font-weight-regular">
                          Всего вопросов
                        </th>
                        <th class="text-left font-weight-regular">
                          Номер правильного ответа
                        </th>
                        <th class="text-left font-weight-regular">
                          Точность
                        </th>
                        <th class="text-left font-weight-regular">
                          Все ответы верные
                        </th>
                        <th class="text-left font-weight-regular">
                          Верная последовательность
                        </th>
                        <th class="text-left font-weight-regular">
                          Время игры
                        </th>
                        <th class="text-left font-weight-regular">
                          Счет
                        </th>
                        <th class="text-left font-weight-regular">
                          Дата
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(round,ind) in taskRounds(item.id)" :key="ind">
                        <td>{{ round.current_level_number }}</td>
                        <td>{{ round.question_number }}</td>
                        <td>{{ round.right_answer_number }}</td>
                        <td>{{ round.accuracy }}</td>
                        <td>{{ round.question_number == round.right_answer_number ? 'Да' : 'Нет' }}</td>
                        <td>{{ round.is_sequence ? 'Да' : 'Нет' }}</td>
                        <td>{{ round.round_time }}</td>
                        <td>{{ round.score }}</td>
                        <td>{{ new Date(round.date) | moment("DD.MM.YYYY HH:mm") }}</td>
                      </tr>
                    </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </template>
        
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    items: {
      default: () => { return []},
      type: Array
    },
    results: {
      default: () => { return []},
      type: Array
    }
  },
  data: () => ({
    opened: null,
  }),
  computed: {
    tasks() {
      return this.items.filter((i) => i.type == 'training')
    }
  },
  methods: {
    taskStartLevel(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';

      return result.start_level;
    },
    taskEndLevel(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      
      if (!result) return '-';

      return result.next_level;
    },
    taskTime(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';

      return result.time;
    },
    taskScore(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';

      return result.score;
    },
    taskAccuracyTotal(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';
      let total = result.rounds.reduce(function(total, item) {
          return total + +item.accuracy;
        },0);
      return total.toFixed(1);
        
    },
    taskAllRightTotal(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';

      return result.rounds.filter(r => r.right_answer_number == r.question_number).length;
    },
    taskSequenceTotal(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return '-';

      return result.rounds.filter(r => r.is_sequence).length;
    },
    taskRoundLength(taskId) {
      return this.taskRounds(taskId).length;
    },
    taskRounds(taskId) {
      let result = this.results.find(r => r.task_id == taskId);
      if (!result) return [];

      return result.rounds;
    }
  }
}
</script>
<style lang="scss">
  
</style>
