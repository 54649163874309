<template>
  <div class="mb-10">
    <!-- Третий график фильтры -->
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
      <v-row>
        <v-col v-if="$store.getters.isExpert  && !userIn" lg="3" sm="4">
          <v-select
            v-model="userId"
            :items="users"
            item-value="id"
            item-text="name"
            dense
            hide-details
            label="Пользователь"
            color="teal"
            class="flex-grow-0"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" lg="3" sm="4">
          <v-select
            v-model="trainingGroup"
            :items="trainingGroups"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Группа тренингов"
            color="teal"
            class="flex-grow-0"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" lg="3" sm="4">
           <v-select
            v-model="training"
            :items="filtredTrainings"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            multiple
            label="Тренинг"
            color="teal"
            class="flex-grow-1"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
      </v-row>
    </v-card>
    <v-card min-height="470" tile class="mb-10 overflow-x-auto">
      <div class="w-100 mw-550">
        <apexchart height="450" :options="options" :series="series"></apexchart>
      </div>
    </v-card>
    <v-card tile class="mb-10">
      <v-simple-table>
        <template>
          <thead class="blue-grey lighten-5">
            <tr class="text-caption grey--text ">
              <th class="text-left font-weight-regular">
                Название тренинга
              </th>
              <th class="text-left font-weight-regular">
                Уровень сложности
              </th>
              <th class="text-left font-weight-regular">
                Баллы
              </th>
              <th class="text-left font-weight-regular">
                Точность
              </th>
              <th class="text-left font-weight-regular">
                Последовательность
              </th>
              <th class="text-left font-weight-regular">
                Скорость реакции
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filtredResults" :key="item.id">
              <td>{{ getTrainingFullName(item) }}</td>
              <td>{{ item.level }}</td>
              <td>{{ item.score }}</td>
              <td>{{ item.accuracy }}</td>
              <td>{{ item.sequence }}</td>
              <td>{{ item.time }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
    
</template>

<script>

export default {
  props: {
    userIn: null,
  },
  data: () => ({
    indicator: null,
    indicators: ['Уровень','Баллы','Точность','Последовательность','Скорость реакции'],
    results: [],
    trainingGroups: [],
    trainingGroup: null,
    training: [],
    trainingsList: [],
    userId: null,
    users: [],
  }),
  created() {
    this.setDefaultUserId();
    
    this.getTrainingGroups();
  },
  computed: {
    categories() {
      return this.filtredResults.map(t => {
        let training = this.trainings.find(tr => tr.id == t.training_id);
        return training ? [training.group.name, training.name] : '';
      });
    },
    levels() {
      return this.filtredResults.map(t => +t.level);
    },
    accuracy() {
      return this.filtredResults.map(t => +t.accuracy);
    },
    sequence_accuracy() {
      return this.filtredResults.map(t => +t.sequence);
    },
    reaction_speed() {
      return this.filtredResults.map(t => +t.speed);
    },
    score() {
      return this.filtredResults.map(t => +t.score);
    },    
    options() {
        let options = {
          chart: {
            type: 'bar',
            zoom: {
              enabled: false
            },
            height: '450px',
          },
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [0, 0, 0, 0, 0],
          },
          xaxis: {
            categories: this.categories,
          },
          yaxis: [
            {
              seriesName: 'Уровень',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008ffb'
              },
              labels: {
                style: {
                  colors: '#008ffb',
                },
                formatter: function(val) {
                  return val ? val.toFixed(0) : '';
                }
              },
              title: {
                text: "Уровень сложности",
                style: {
                  color: '#008ffb', // синий
                }
              },
              tooltip: {
                enabled: true
              }
            },
            {
              seriesName: 'Память',
              min: 0,
              max: 100,
              opposite: true,                  
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#00e396'// зеленый
              },
              labels: {
                style: {
                  colors: '#00e396',
                },
                formatter: function(val) {
                  return val ? val.toFixed(0) : '';
                }
              },
              title: {
                text:  "Память",
                style: {
                  color: '#00e396',
                }
              },
            },
            {
              min: 0,
              max: 100,
              seriesName: 'Внимание',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#feb019' // желтый
              },
              labels: {
                style: {
                  colors: '#feb019',
                },
                formatter: function(val) {
                  return val ? val.toFixed(0) : '';
                }
                
              },
              title: {
                text:  "Внимание",
                style: {
                  color: '#feb019',
                }
              }
            },
            {
              min: 0,
              max: 100,
              seriesName:  'Скорость реакции',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#ff4560' // красный
              },
              labels: {
                style: {
                  colors: '#ff4560',
                },
                formatter: function(val) {
                  return val ? val.toFixed(0) : '';
                }
              },
              title: {
                text:  "Скорость реакции",
                style: {
                  color: '#ff4560',
                }
              }
            },
            {
              seriesName: 'Баллы',
              min: 0,
              max: 100,
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#775dd0' // Фиолетовый
              },
              labels: {
                style: {
                  colors: '#775dd0',
                },
                formatter: function(val) {
                  return val ? val.toFixed(0) : '';
                }
              },
              title: {
                text: "Баллы",
                style: {
                  color: '#775dd0',
                }
              }
            },
          ],
        }
        return options;
    },     
    series() {
        let data = [
          {
            name: 'Уровень',
            type: 'bar',
            data: this.levels
          }, 
          {
            name: 'Память',
            type: 'bar',
            data: this.accuracy
          },
          {
            name: 'Внимание',
            type: 'bar',
            data: this.sequence_accuracy
          },
          {
            name: 'Скорость реакции',
            type: 'bar',
            data: this.reaction_speed
          },
          {
            name: 'Баллы',
            type: 'bar',
            data: this.score
          }
        ];
        return data
    },
    groupIds() {
        let ids = [];
        if (this.userGroup) {
          ids = this.$store.state.userGroups
            .filter(g => g.id == this.userGroup || g.parent_id == this.userGroup)
            .map(g => g.id)
        }
        return ids;
    },
    trainings() {
        return this.$store.state.trainings;
    },
    filtredTrainings() {
        let trainings = this.trainings;
        if (this.trainingGroup) {
          trainings = trainings.filter(g => this.trainingGroup == g.group.id)
        }
        return trainings;
    },
    filtredResults() {
        let results = this.results;
        if (this.training.length) {
          results = results.filter(r => this.training.includes(r.training_id))
        }
        if (this.trainingGroup) {
          results = results.filter(r => r.group_id == this.trainingGroup)
        } 
        return results;
    }
  },
  watch: {
    userId() {
      if (this.userId) {
        this.getResults();
      }
    }
  },
  methods: {
    setDefaultUserId() {
      if (this.$store.state.auth.user.role == 'client') {
        this.userId = this.$store.state.auth.user.id;
      } else if (this.userIn) {
        this.userId = this.userIn.id;
      } else {
        this.getUsers();
      }
    },
    getUsers() {
      let url = '/users';
      this.$api.get(url)
        .then(resp => {          
          let users = resp.data._embedded.users
            .filter(u => u.role.id == 1)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.users = users;
          if (users.length) {
            this.userId = users[0].id;
          }
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getTrainingFullName(t) {
      let training = this.trainings.find(tr => tr.id == t.training_id);
      return training ? `${training.group.name}. ${training.name}.` : '';
    },
    getTrainingGroups() {
      this.loading = true
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.trainingGroups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getResults() {
      let url = '/results/user/trainings';
      let params = {
        user_id: this.userId,
      };
      this.$api.get(url,{params})
        .then(resp => {
          this.results = resp.data._embedded.trainings;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    /* clearFilter() {
      this.user = null;
      this.userGroup = null;
      this.training = null;
      this.trainingGroup = null;
    }, */
  }
}
</script>
