<template>
  <div>
    <v-skeleton-loader v-if="loading" type="image, table-row-divider@3"></v-skeleton-loader>
    <trainings-chart v-if="!loading" :syllabusId="syllabus.id" :results="results" :tasks="tasks" ></trainings-chart>
    <v-expansion-panels v-if="!loading" tile accordion>
      <v-expansion-panel
        v-for="day in tasks.days"
        :key="day.number"
        active-class="pb-5"
      >
        <v-expansion-panel-header class="px-2 py-0">
          <template v-slot:actions class="order-1">
            <v-icon color="teal" >
              mdi-menu-down
            </v-icon>
          </template>
          <v-row align="center" class="my-0 order-2" dense>
            <v-col cols="12" sm="3">
              <div class="ml-2">День {{ day.number }}</div>
            </v-col>
            <v-spacer/>
            <v-col cols="5" sm="2" lg="1">
              <div class="text-caption grey--text">Тренингов:</div>
              {{ day.tasks.filter((t) => { return t.type == 'training' && t.completed }).length }} / {{ day.tasks.filter(t => t.type == 'training').length }}
            </v-col>
            <v-col cols="5" sm="2" lg="1">
              <div v-if="day.date">
                <div class="text-caption grey--text">Дата:</div>
                {{ new Date(day.date) | moment("DD.MM.YYYY") }}
              </div>
            </v-col>
            <v-col  cols="auto"  >
              <v-progress-circular
                :rotate="-90"
                :size="30"
                :width="3"
                :value="day.percent"
                color="teal"
                class="text-caption"
              >
              {{ day.percent }}
              </v-progress-circular>
            </v-col>            
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <tasks-list :items="day.tasks" :results="results"></tasks-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
 
import TasksList from './TasksList'
import TrainingsChart from './TrainingsChart'
export default {
  components: {
    TasksList,
    TrainingsChart
  },
  props: {
    syllabus: null,
  },
  data: () => ({
    loadingTasks: true,
    loadingResults: true,
    tasks: () => { return [] },
    results: () => { return [] },
  }),
  created() {
    this.getTasks();
    this.getResults();
  },
  computed: {
    loading() {
      return this.loadingTasks || this.loadingResults;
    }
  },
  methods: {
    getTasks() {
      let url = '/tasks';
      let params = {
        syllabus_id: this.syllabus.id,
      };
      this.$api.get(url,{params})
        .then(resp => {
          this.tasks = resp.data._embedded.tasks;
          this.loadingTasks = false;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getResults() {
      let url = `/syllabi/${this.syllabus.id}/results`;
       this.$api.get(url)
        .then(resp => {
          this.results = resp.data._embedded.results;
          this.loadingResults = false;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>