<template>
    <v-card min-height="300" tile class="mb-3">
      <apexchart height="300" type="line" :options="options" :series="series"></apexchart>
    </v-card>
</template>

<script>
 
export default {
  props: {
    syllabusId: null,
    results: {
      default: () => { return []},
    },
    tasks: {
      default: () => { return []},
    },
  },
  data: () => ({
    trainings: null,
  }),
  created() {
      this.getTrainings();
  },
  computed: {
      options() {
        let options = {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
            height: '400px',
            toolbar: {
              show: false
            }
          },
          toolbar: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
            title: {
              text: 'Дeнь'
            }
          }
        }
        return options;
      },
      days() {
        if (!this.tasks.days) {
          return []
        }
        return this.tasks.days.map(d => {return d.number})
      },
      series () {
        if (!this.trainings) {
          return []
        }
        let data = [];
        let trainings = this.trainings;
        trainings.forEach(training => {
          let item = {name: `${training.name} (${training.group})`, data: []}
          
          let values = [];
          this.days.forEach((day) => {
            let value = null;
            let result = this.results.find(r => { return r.day == day && r.training_id == training.id});
            if (result) {
              value = result.end_level
            }
            values.push(value);
          })
          item.data = values;
          data.push(item);
        })
        return data/* [
          {name: 'game 1', data:[2,3,4,5,4]},
          {name: 'game 2', data:[3,2,1,4,5]}
        ] */
      }
  },
  methods: {
    getTrainings() {
      this.loading = true
      let url = `/syllabi/${this.syllabusId}/trainings`;
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.trainings = resp.data._embedded.trainings;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>