<template>
  <div class="mb-10">
    <v-skeleton-loader v-if="!isLoaded" type="article"></v-skeleton-loader>
    <div v-else>
      <v-card v-if="userIn" color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
        <v-row>
          <v-col cols="12">
            <v-row v-for="(line,index) in filterLines" :key="line.key" class="align-center">
              
              
              <v-col cols="12">                
                <div class="d-flex gr-filter-row">
                  <div class="mt-2 mr-2">№{{ index + 1}} </div>
                  
                    <v-dialog
                      v-model="line.modal"
                      persistent
                      width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="line.datesText"
                          label="Временной интервал"
                          readonly
                          dense
                          @click:clear="clearLineDates(line)"
                          
                          hide-details
                          clearable
                          color="teal"
                          v-bind="attrs"
                          v-on="on"
                          class="flex-grow-0 ml-2"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="line.dates"
                        scrollable
                        first-day-of-week="1"
                        range
                        color="teal">
                        <v-spacer></v-spacer>
                        <v-btn text color="blue-grey" @click="line.modal = false" >
                          Отмена
                        </v-btn>
                        <v-btn text color="teal" @click="setLineDatesText(line)">
                          Сохранить
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                    <v-btn 
                      v-if="filterLines.length > 1" 
                      @click="removeLine(index)"
                      fab
                      dark
                      color="error"
                      x-small
                      class="ml-2 gr-filter-remove">
                      <v-icon dark>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                    <div class="d-flex gr-add-row">
                      <v-spacer></v-spacer>
                      <v-select
                        v-model="assort"
                        :items="assorts"
                        @change="getAllResults()"
                        item-value="key"
                        item-text="name"
                        dense
                        hide-details
                        label="Группировать"
                        color="teal"
                        class="ml-5 gr-sort"
                        item-color="teal lighten-1">
                      </v-select>
                    </div>
                </div>
                
              </v-col>
             
            </v-row>
            <v-row class="mt-5">
              <v-col cols="12" >
                
                
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
        <v-row>
          <v-col cols="12">
            <v-row v-for="(line,index) in filterLines" :key="line.key" class="align-center">
              
              
              <v-col cols="12">                
                <div class="d-flex gr-filter-row">
                  <div class="mt-2 mr-2">№{{ index + 1}} </div>
                   <select-user-group class="mr-2" v-model="line.userGroup" @change="changeLineUsersList(line);getResults(line)"></select-user-group>
                  <v-select
                    v-model="line.user"
                    @change="getResults(line)"
                    :items="line.users"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    clearable
                    label="Пользователь"
                    color="teal"
                    class="flex-grow-0 mr-2"
                    item-color="teal lighten-1">
                  </v-select>
                  <v-select
                    v-model="line.trainingGroup"
                    :items="trainingGroups"
                    @change="filterLineTrainings(line);getResults(line)"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    clearable
                    label="Группа тренингов"
                    color="teal"
                    class="mr-2"
                    item-color="teal lighten-1">
                  </v-select>
                  <v-select
                    v-model="line.training"
                    :items="line.trainings"
                    @change="getResults(line)"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    clearable
                    label="Тренинг"
                    color="teal"
                    class="mr-2"
                    item-color="teal lighten-1">
                  </v-select>
                  <v-select
                    v-if="tags.length"
                    v-model="line.tag"
                    @change="getResults(line)"
                    :items="tags"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    clearable
                    label="Тег"
                    color="teal"
                    item-color="teal lighten-1">
                  </v-select>
                  <v-dialog
                    v-model="line.modal"
                    persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="line.datesText"
                        label="Временной интервал"
                        readonly
                        dense
                        @click:clear="clearLineDates(line)"
                        
                        hide-details
                        clearable
                        color="teal"
                        v-bind="attrs"
                        v-on="on"
                        class="flex-grow-0 ml-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="line.dates"
                      scrollable
                      first-day-of-week="1"
                      range
                      color="teal">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="line.modal = false" >
                        Отмена
                      </v-btn>
                      <v-btn text color="teal" @click="setLineDatesText(line)">
                        Сохранить
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-btn 
                  v-if="filterLines.length > 1" 
                  @click="removeLine(index)"
                  fab
                  dark
                  color="error"
                  x-small
                  class="ml-2 gr-filter-remove">
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
                </div>
              </v-col>
             
            </v-row>
            <v-row class="mt-5">
              <v-col cols="12" >
                <div class="d-flex gr-add-row">
                  <v-btn @click="addFilterLine" dark color="teal lighten-2 ">
                    <v-icon left>
                      mdi-plus-thick
                    </v-icon>
                    Добавить фильтр
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-select
                    v-model="assort"
                    :items="assorts"
                    @change="getAllResults()"
                    item-value="key"
                    item-text="name"
                    dense
                    hide-details
                    label="Группировать"
                    color="teal"
                    class="ml-5 gr-sort"
                    item-color="teal lighten-1">
                  </v-select>
                </div>
                
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card min-height="470" tile class="mb-10 overflow-x-auto">
        <div class="w-100 mw-550">
          <apexchart height="450" type="line" :options="options" :series="series"></apexchart>
        </div>
      </v-card>
    </div>
  </div>
    
</template>

<script>

import SelectUserGroup from '../main/SelectUserGroup'
export default {
  props: {
    userIn: null,
  },
  components: {
    SelectUserGroup
  },
  data: () => ({
    trainingGroupsLoaded: false,
    results: [],
    assort: 'day',
    assorts: [
      {
        key: 'day',
        name: 'По дням'
      }, 
      {
        key: 'session',
        name: 'По сессиям'
      }, 
    ],
    filterLines: [],
    modal: false,
    indicator: null,
    indicators: ['Уровень','Память','Внимание','Скорость реакции','Баллы'],
    userGroup: null,
    user: null,
    levels: [],
    accuracy: [],
    sequence_accuracy: [],
    round_time: [],
    trainingGroups: [],
    trainingGroup: [],
    training: null,
    score: [],
    parametrs: [
      {key: 'end_level', name: 'Уровень'},
      {key: 'accuracy', name: 'Память, %'},
      {key: 'sequence_accuracy', name: 'Внимание, %'},
      {key: 'round_time', name: 'Скорость реакции, %'},
      {key: 'score', name: 'Баллы'},
    ] 
  }),
  created() {
    if(this.userIn) {
      this.user = this.userIn;
    }
    this.getTrainingGroups();
  },
  watch: {
    isLoaded() {
      this.addFilterLine();
    },
    userGroup() {
      let user = this.filtredUsers.find(u => u.id == this.user);
      if (!user) {
        this.user = null;
      }
    },
    trainingGroup() {
      let training = this.filtredTrainings.find(t => t.id == this.training);
      if (!training) {
        this.training = null;
      }
    },
  },
  computed: {
    isLoaded() {
      return this.usersLoaded && this.trainingGroupsLoaded;
    },
    users() {
      return this.$store.state.users;
    },
    tags() {
      return this.$store.state.tags;
    },
    usersLoaded() {
      return !!this.users.length;
    },
    categories() {
      let count = this.results.length ? Math.max(...this.results.map(r => r.values.length)) : 0;
      let categories = []
      let label = this.assort == 'day' ? 'День ' : 'Сессия ';
      for (let index = 1; index <= count; index++) {
        categories.push(label+index);          
      }
      return categories;
    },
    options() {
      let options = {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          height: '450px',
          stacked: false
        },
        toolbar: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 4,
          curve: 'smooth',
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: [
          { // Уровень
            seriesName: 'Уровень',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008ffb'
            },
            labels: {
              style: {
                colors: '#008ffb',
              },
              formatter: function(val) {
                return val.toFixed(0);
              }
            },
            title: {
              text: "Уровень сложности",
              style: {
                color: '#008ffb', // синий
              }
            },
            tooltip: {
              enabled: true
            }
          },
          { // Память
            seriesName: 'Память',
            min: 0,
            max: 100,
            opposite: true,                  
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00e396'// зеленый
            },
            labels: {
              style: {
                colors: '#00e396',
              },
              formatter: function(val) {
                return val ? val.toFixed(0) : '';
              }
            },
            title: {
              text:  "Память",
              style: {
                color: '#00e396',
              }
            },
          },
          { // Внимание
            min: 0,
            max: 100,
            seriesName:  'Внимание',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#feb019' // желтый
            },
            labels: {
              style: {
                colors: '#feb019',
              },
              formatter: function(val) {
                return val ? val.toFixed(0) : '';
              }
            },
            title: {
              text:  "Внимание",
              style: {
                color: '#feb019',
              }
            }
          },
          { // Скорость реакции
            min: 0,
            max: 100,
            seriesName:  'Скорость реакции',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff4560' // красный
            },
            labels: {
              style: {
                colors: '#ff4560',
              },
              formatter: function(val) {
                return val ? val.toFixed(0) : '';
              }
            },
            title: {
              text:  "Скорость реакции",
              style: {
                color: '#ff4560',
              }
            }
          },
          { // Баллы
            seriesName: 'Баллы',
            min: 0,
            /* max: 100, */
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#775dd0' // Фиолетовый
            },
            labels: {
              style: {
                colors: '#775dd0',
              },
              formatter: function(val) {
                return val ? val.toFixed(0) : '';
              }
            },
            title: {
              text: "Баллы",
              style: {
                color: '#775dd0',
              }
            }
          },
          {
            seriesName: '1. Уровень',
            show: false,
          },
          {
            seriesName: '1. Память',
            show: false,
          },
          {
            seriesName: '1. Внимание',
            show: false,
          },
          {
            seriesName: '1. Скорость реакции',
            show: false,
          },          
          {
            seriesName: '1. Баллы',
            show: false,
          },
          {
            seriesName: '1. Уровень',
            show: false,
          },
          {
            seriesName: '1. Память',
            show: false,
          },
          {
            seriesName: '1. Внимание',
            show: false,
          },
          {
            seriesName: '1. Скорость реакции',
            show: false,
          },          
          {
            seriesName: '1. Баллы',
            show: false,
          },
          {
            seriesName: '1. Уровень',
            show: false,
          },
          {
            seriesName: '1. Память',
            show: false,
          },
          {
            seriesName: '1. Внимание',
            show: false,
          },
          {
            seriesName: '1. Скорость реакции',
            show: false,
          },          
          {
            seriesName: '1. Баллы',
            show: false,
          },
          
        ],
      }
      return options;
    },     
    series() {
      let data = [];
      this.results.forEach((r) => {
        data.push({
          name: r.name + 'Уровень',
          data: r.values.end_level
        });
        data.push({
            name: r.name + 'Память',
            data: r.values.accuracy
        });
        data.push({
          name: r.name + 'Внимание',
          data: r.values.sequence_accuracy
        });
        data.push({
          name: r.name + 'Скорость реакции',
          data: r.values.reaction_speed
        });
        data.push({
          name: r.name + 'Баллы',
          data: r.values.score
        });
      });
      return data;
    },
    trainings() {
        return this.$store.state.trainings;
    },
    filtredTrainings() {
        let trainings = this.trainings;
        if (this.trainingGroup) {
          trainings = trainings.filter(g => this.trainingGroup == g.group.id)
        }
        return trainings;
    },
    filtredUsers() {
      let users = this.users;
      if (this.userGroup) {
        users = users.filter(u => this.groupIds.includes(u.group.id));
      }
      return users;
    },      
  },
  methods: {
    addFilterLine() {
      
      let count = this.filterLines.length;
      let line = {
        trainingGroup: null,
        trainings: this.trainings,
        training: null,
        user: this.userIn ? this.userIn.id : null ,
        tag: null,
        users: [...this.users],
        userGroup: [],
        modal: false,
        parameter: 'level',
        key: Math.random().toString(36),
      }
      if (!this.userIn) {
        let date = this.$moment()
        let endDate = date.toISOString().slice(0,10);
        date.subtract(1, 'months')
        let startDate = date.toISOString().slice(0,10);
        line.dates = [startDate,endDate];
        line.datesText = startDate+' ~ '+endDate;

      }
      this.$set(this.filterLines, count, line);
      this.getResults(line);
    },
    /* getUsers() {
      let url = '/users';
       this.$api.get(url)
        .then(resp => {          
          let users = resp.data._embedded.users
            .filter(u => u.role.id == 1)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.users = users;
          this.usersLoaded = true;
          console.log('users load');
          this.filterLines.forEach((l) => {
            console.log(l.users); 
          })
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }, */
    getTrainingGroups() {
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.trainingGroups = resp.data._embedded.groups;
          this.trainingGroupsLoaded = true;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getAllResults() {
      this.filterLines.forEach(line => this.getResults(line));
    },
    getResults(line) { 
      let url = '/results';
      let params = { 
        user_groups: this.groupIds(line.userGroup),
        user_id: line.user,
        tag_id: line.tag,
        training_group_id: line.trainingGroup,
        training_id: line.training,
        dates: line.dates,
        assort: this.assort,
      };
      this.$api.get(url,{params})
        .then(resp => {
          let index = this.results.findIndex(r => r.key == line.key);
          if (index == -1) {
            index = this.results.length;
          }
          let name = `${index + 1}. `;
          let result = result = {
              ...params,
              name,
              key: line.key,
              values:  resp.data._embedded.values
          }
          
          this.$set(this.results, index, result);
        })
        .catch(error => {   
          console.log(error);        
          this.$store.dispatch('addRequestError', error)
        })
    },  
    clearFilter() {
      this.user = null;
      this.userGroup = null;
      this.training = null;
      this.trainingGroup = null;
    },
    removeLine(line) {
      if (this.filterLines.length > 1) {
        let lineIndex = this.filterLines.findIndex(l => l.key = line.key)
        let resultIndex = this.results.findIndex(r => r.key = line.key)
        this.filterLines.splice(lineIndex,1);
        this.results.splice(resultIndex,1);
      }
    },
    setLineDatesText(line) {
      line.datesText = line.dates.join(' ~ ');
      line.modal = false;
      this.getResults(line);
    },
    clearLineDates(line) {
      line.dates = [];
      this.getResults(line);
    },
    changeLineUsersList(line) {
      let users = this.users;
      if (line.userGroup && line.userGroup.length) {
        let groupIds = this.groupIds(line.userGroup);
        users = users.filter(u => groupIds.includes(u.group.id));
      }
      line.users = users;      
    },
    filterLineTrainings(line) {
      let trainings = this.trainings;
      if (line.trainingGroup) {
        trainings = trainings.filter(t => t.group.id == line.trainingGroup);
      }
      line.trainings = trainings; 
      if (line.training && !trainings.find(t => t.id == line.training)) {
        line.training = null;
      }     
    },
    groupIds(id) {
      let ids = [];
      if (id) {        
        ids = this.$store.state.userGroups
          .filter(g => g.id == id || g.parent_id == id)
          .map(g => g.id);
      }
      return ids;
    },
  }
}
</script>

<style>

</style>
