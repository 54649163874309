<template>
  <div class="mb-10">
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
      <v-row>
        <v-col cols="12" lg="10">
          <v-row v-for="(line,index) in filterLines" :key="line.key" class="align-center">
            <v-col v-if="$store.getters.isExpert && !userIn" cols="12" sm="3">
              <v-select
                v-if="!index"
                v-model="userId"
                :items="users"
                item-value="id"
                item-text="name"
                dense
                hide-details
                label="Пользователь"
                color="teal"
                class="flex-grow-0"
                item-color="teal lighten-1">
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                v-model="line.trainingGroup"
                :items="trainingGroups"
                @change="filterLineTrainings(line.key);getResults(line.key)"
                item-value="id"
                item-text="name"
                dense
                hide-details
                clearable
                label="Группа тренингов"
                color="teal"
                item-color="teal lighten-1">
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                v-model="line.training"
                :items="line.trainings"
                @change="getResults(line.key)"
                item-value="id"
                item-text="name"
                dense
                hide-details
                clearable
                label="Тренинг"
                color="teal"
                item-color="teal lighten-1">
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
               <v-select
                v-model="line.parameter"
                :items="indicators"
                @change="getResults(line.key)"
                item-value="key"
                item-text="name"
                dense
                hide-details
                label="Показатель"
                color="teal"
                item-color="teal lighten-1">
              </v-select>
            </v-col>
            
            <v-col v-if="(userIn && !index)">                       
              <v-select
                v-model="assort"
                :items="assorts"
                item-value="key"
                item-text="name"
                dense
                hide-details
                label="Группировать"
                color="teal"
                class="ml-5 gr-sort"
                item-color="teal lighten-1">
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div class="d-flex gr-add-row mt-5">
            
            <v-btn @click="addFilterLine" dark color="teal lighten-2 ">
              <v-icon left>
                mdi-plus-thick
              </v-icon>
              Добавить фильтр
            </v-btn>
            <template v-if=!userIn>
              <v-spacer></v-spacer>           
              <v-select
                v-model="assort"
                :items="assorts"
                item-value="key"
                item-text="name"
                dense
                hide-details
                label="Группировать"
                color="teal"
                class="ml-5 gr-sort"
                item-color="teal lighten-1">
              </v-select>
            </template>
          </div>
        </v-col>
        
      </v-row>
    </v-card>
    <v-card min-height="470" tile class="mb-10">
      <div class="w-100">
        <apexchart height="450" :options="options" :chart-id="chartId" :series="series"></apexchart>
      </div>
    </v-card>
  </div>
    
</template>

<script>

export default {
  props: {
    userIn: null,
  },
  data: () => ({
    chartId: Math.random().toString(36),
    indicator: null,
    indicators: [
      {
        key: 'level',
        name: 'Уровень. Факт'
      }, 
      {
        key: 'level_plan',
        name: 'Уровень. План'
      }, 
      {
        key: 'score',
        name: 'Баллы'
      }, 
      {
        key: 'accuracy',
        name: 'Память'
      }, 
      {
        key: 'sequence_accuracy',
        name: 'Внимание'
      }, 
      {
        key: 'round_time',
        name: 'Скорость реакции'
      }, 
    ],
    results: [],
    assort: 'day',
    assorts: [
      {
        key: 'day',
        name: 'По дням'
      }, 
      {
        key: 'session',
        name: 'По сессиям'
      }, 
    ],
    filterLines: [],
    trainingGroups: [],
    trainingGroup: null,
    training: [],
    userId: null,
    users: [],
  }),
  created() {
    
    this.addFilterLine();
    this.getTrainingGroups();
    this.setDefaultUserId();
  },
  computed: {
    categories() {
      let count = this.filteredResults.length ? Math.max(...this.filteredResults.map(r => r.values.length)) : 0;
      let categories = []
      let label = this.assort == 'day' ? 'День ' : 'Сессия ';
      for (let index = 1; index <= count; index++) {
        categories.push(label+index);          
      }
      return categories;
    },
    
    options() {
      let options = {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          height: '450px',
          stacked: false
        },
        toolbar: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [4, 4, 4, 4],
          curve: 'smooth',
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: this.filteredResults.filter(r => r.values.length).map((r,index) => {
          return {
            seriesName: r.name,
            opposite: !!index,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: r.name,
            },
            tooltip: {
              enabled: true
            },
            labels: {
              formatter: function(val) {
                return val ? val.toFixed(0) : '';
              }
            },
          }
        }), 
      }
      return options;
    },   
    series() {
      let data =  this.filteredResults.filter(r => r.values.length).map(r => {
        return {
          name: r.name,
          type: 'line',
          data: r.values
        }
      });
      return data;
    },
    groupIds() {
      let ids = [];
      if (this.userGroup) {
        ids = this.$store.state.userGroups
          .filter(g => g.id == this.userGroup || g.parent_id == this.userGroup)
          .map(g => g.id)
      }
      return ids;
    },
    trainings() {
      return this.$store.state.trainings;
    },
    filteredTrainings() {
      let trainings = this.trainings;
      if (this.trainingGroup) {
        trainings = trainings.filter(g => this.trainingGroup == g.group.id)
      }
      return trainings;
    },
    filteredResults() {
      let results = this.linesWithResults.map(l => this.getResultsValue(l));
      return results;
    },
    linesWithResults() {
      return this.filterLines.filter(l => !!this.getResultsValue(l))
    }
  },
  watch: {
    trainings() {
      this.filterLines = [];
      this.addFilterLine();
    },
    assort() {
      this.filterLines.forEach(l => {
        this.getResults(l.key)
      })
    },
    userId() {
      if (this.userId) {
        this.filterLines.forEach(l => {
          this.getResults(l.key)
        })
      }
    }
  },
  methods: {
    setDefaultUserId() {
      if (this.$store.state.auth.user.role == 'client') {
        this.userId = this.$store.state.auth.user.id;
      } else if (this.userIn) {
        this.userId = this.userIn.id;
      } else {
        this.getUsers();
      }
    },
    getUsers() {
      let url = '/users';
      this.$api.get(url)
        .then(resp => {          
          let users = resp.data._embedded.users
            .filter(u => u.role.id == 1)
            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.users = users;
          if (users.length) {
            this.userId = users[0].id;
          }
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    addFilterLine() {
      let count = this.filterLines.length;
      let line = {
        trainingGroup: null,
        trainings: this.trainings,
        training: null,
        parameter: 'level',
        key: Math.random().toString(36),
      }
      this.$set(this.filterLines, count, line);
      this.getResults(line.key);
    },
    filterLineTrainings(key) {
      let line = this.filterLines.find(l => l.key == key);
      let trainings = this.trainings;
      if (line.trainingGroup) {
        trainings = trainings.filter(g => line.trainingGroup == g.group.id)
      }
      line.trainings = trainings;
    },
    getTrainingGroups() {
      
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.loading = false;
          this.trainingGroups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    getResults(key) {
      //if (this.results.find(r => r.))
      
      let line = this.filterLines.find(l => l.key == key);
      

      if (this.getResultsValue(line)) {
        return
      } 
      if (!this.userId) {
        return
      } 
      let url = '/results/user/parameters';
      let params = { 
        training_id: line.training,
        training_group_id: line.trainingGroup,
        parameter: line.parameter,
        assort: this.assort,
        user_id: +this.userId,
      };
      this.$api.get(url, {params})
        .then((resp) => {
          let name = '';
          console.log(resp.data);
          if (line.trainingGroup) {
            
            name += this.trainingGroups.find(g => g.id == line.trainingGroup).name + '. ';
          }
          if (line.training) {
            name += this.trainings.find(t => t.id == line.training).name + '. ';
          }
          name += this.indicators.find(i => i.key == line.parameter).name;
          
          let result = {
            ...params, 
            values: resp.data._embedded.values,
            name: name
          }
          this.$set(this.results, this.results.length, result);
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    /* clearFilter() {
      this.user = null;
      this.userGroup = null;
      this.training = null;
      this.trainingGroup = null;
    }, */
    getResultsValue(line) {
      let res = this.results.find(r => 
        r.training_id == line.training && 
        r.training_group_id == line.trainingGroup &&
        r.assort == this.assort &&
        r.parameter == line.parameter &&
        r.user_id == this.userId
      );
      return res;
    },
  }
}
</script>
